.product-nav-section-preview {
  $preview-group: ".product-nav-section__preview-group";

  $this_background-color: $color-gray-l-77;
  $this_height: rem(179px);
  $this_min-md_height: rem(167px);
  $this_min-xl_height: rem(156px);

  height: $this_height;
  background-color: $this_background-color;

  position: relative;
  transition: all 0.3s linear;

  overflow: hidden;

  &__image-wrapper {
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;

    transition: all 0.45s ease-out;
  }

  &__image {
    margin: 0 auto;
  }

  #{$preview-group} &:first-child &__image {
    transform: translate(-30%, 25%);
  }

  #{$preview-group} &:last-child &__image {
    transform: translateY(50%);
  }

  #{$preview-group} &:first-child:hover ~ & {
    transform: translateX(10%);
  }

  #{$preview-group} &:hover &__image-wrapper {
    transform: translateX(-5%);
  }

  #{$preview-group} &:last-child:hover {
    transform: translateX(-10%);
  }

  #{$preview-group} &:last-child:hover &__image-wrapper {
    transform: translateX(-5%);
  }

  @include media-min(md) {
    height: $this_min-md_height;
  }

  @include media-min(xl) {
    height: $this_min-xl_height;
  }
}
