.advantages {
  $this_padding: rem(120px) 0 rem(225px) 0;
  $this_max-xl_padding-bottom: rem(142px);
  $this_max-sm_padding: rem(60px) 0 rem(94px);

  $header_margin-bottom: rem(45px);
  $header_margin-bottom_min-md: rem(75px);
  $header_margin-bottom_min-xl: rem(115px);

  $subtitle-container_margin-top: rem(9px);
  $subtitle-container_margin-top_min-md: 0;
  $subtitle-container_margin-top_min-xl: rem(9px);

  $slide-switcher_margin-bottom_min-sm: rem(65px);
  $slide-switcher_margin-bottom_min-xl: rem(50px);

  $aside_margin-bottom: rem(60px);
  $aside_margin-bottom_min-md: 0;

  padding: $this_padding;

  &__title-container + &__subtitle-container {
    margin-top: $subtitle-container_margin-top;
  }

  &__subtitle-container {
    align-self: flex-end;
  }

  &__aside {
    margin-bottom: $aside_margin-bottom;
  }

  &__header {
    margin-bottom: $header_margin-bottom;
  }

  &__aside-header {
    display: none;
  }

  @include media-max(md) {
    &__title {
      font-size: rem(24px);
    }
  }

  @include media-min(md) {
    &__slide-switcher {
      margin-bottom: $slide-switcher_margin-bottom_min-sm;
    }

    &__title-container + &__subtitle-container {
      margin-top: $subtitle-container_margin-top_min-md;
    }

    &__title {
      white-space: pre-line;
    }

    &__title,
    &__subtitle {
      display: inline-block;
      vertical-align: baseline;
    }

    &__header {
      margin-bottom: $header_margin-bottom_min-md;
    }

    &__aside {
      margin-bottom: $aside_margin-bottom_min-md;
    }
  }

  @include media-min(xl) {
    &__header {
      display: none;
    }

    &__aside-header {
      position: relative;
      display: block;
      margin-bottom: $header_margin-bottom_min-xl;
    }

    &__title-container + &__subtitle-container {
      position: absolute;

      bottom: -$subtitle-container_margin-top_min-xl;
      left: 0;

      margin: 0;

      transform: translateY(100%);
    }

    &__slide-switcher {
      margin-bottom: $slide-switcher_margin-bottom_min-xl;
    }
  }
}
