.copyright-main {
  $this_font-size: rem(15px);

  display: inline-flex;

  flex-direction: column;

  font-size: $this_font-size;

  $copyright_margin-bottom: rem(4px);

  &__policy {
    order: 1;
  }

  &__copyright {
    margin-bottom: $copyright_margin-bottom;
    order: 0;
  }

  @include media-min(md) {
    flex-direction: row;

    &__policy {
      white-space: pre-wrap;
      order: 0;
    }

    &__copyright {
      order: 1;
    }
  }

  @include media-min(xl) {
    align-items: flex-end;

    &__copyright {
      margin-bottom: 0;
    }
  }
}
