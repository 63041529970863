.raw-html {
  ul {
    position: relative;
    margin: 0;
    padding: 0 0 0 rem(20px);
    list-style: none;

    li {
      &:before {
        @include custom-list-marker($list-item-marker-disc-size, $list-item-marker-disc);
      }

      &:not(:last-child) {
        margin-bottom: em(8px);
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-family-gl-interstate;
    font-weight: $font-weight-regular;
    line-height: 1;
  }

  h3 {
    font-size: em(20px);
    margin-bottom: em(24px, 20px);
  }

  ul + * {
    margin-top: em(30px);
  }

  * + ul {
    margin-top: em(30px);
  }

  p + * {
    margin-top: 1em;
  }

  table {
    margin: em(15px) 0;
  }

  td {
    padding: 0 em(15px) 0 0;
  }

  tr:not(:last-child) td {
    padding-bottom: em(10px);
  }
}
