$contacts-main-item_margin-bottom: rem(44px);
$contacts-main-item_min-md_margin-bottom: rem(41px);

.contacts-main {
  $item_margin-bottom: $contacts-main-item_margin-bottom;
  $item_min-md_margin-bottom: $contacts-main-item_min-md_margin-bottom;
  $item_min-xl_margin-bottom: rem(32px);

  $items_gap: rem(20px);
  $items_gap-half: $items_gap / 2;

  overflow: hidden;

  &__inner {
    margin-left: -$items_gap-half;
    margin-right: -$items_gap-half;
  }

  &__item {
    padding-left: $items_gap-half;
    padding-right: $items_gap-half;

    margin-bottom: $item_margin-bottom;
  }

  @include media-max(xs) {
    &__item {
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  @include media-min(xl) {
    &__item {
      // margin-bottom: $item_min-xl_margin-bottom;
    }

    &__inner {
      height: 100%;
      align-content: flex-start;
    }
  }
}
