.caption {
  $this_font-size: rem(15px);
  $this_font-size_min-md: rem(17px);

  $this_line-height: $line-height-sm;
  $this_color: $color-gray-chateau;
  $this_font-family: $font-family-anonymous-pro;

  $this-primary_color: $primary-color;
  $this-secondary_color: $color-gray-l-61;

  font-size: $this_font-size;
  line-height: $this_line-height;

  font-family: $this_font-family;

  &_accent {
    color: $this-primary_color;
  }

  &_secondary {
    color: $this-secondary_color;
  }

  @include media-min(md) {
    font-size: $this_font-size_min-md;
  }
}
