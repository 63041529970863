.overlay {
  position: fixed;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: none;
  user-select: none;

  backface-visibility: hidden;

  &_view {
    background-color: $color-gray-l-88;
    transform-origin: right top;
    will-change: scale;
    transform: translateZ(0);
    backface-visibility: hidden;
    z-index: 155;
  }

  &_active {
    display: block;
  }

  &_body {
    display: block;
    background-color: $background-color-base;
  }

  &_prevent-click,
  &_body {
    z-index: 9999;
  }
}
