.manufacture-view-slider {
  &__slides {
    padding: 0;
    margin: 0;

    list-style: none;
  }

  &__slide {
    width: auto;
    // @include media-max(lg){
    //   height: rem(200px);
    // }
  }

  &__image {
    width: 100%;

    height: rem(400px);

    object-fit: cover;
  }

  @include media-min(sm) {
    &__image {
      width: auto;
      height: rem(200px);
    }
  }

  @include media-min(lg) {
    &__image {
      height: rem(460px);
    }
  }
}
