.features-aside {
  $header_margin-bottom: rem(176px);
  $header_max-xl_margin-bottom: rem(163px);
  $header_max-sm_margin-bottom: rem(30px);

  $pagination_margin-right_min-xl: rem(70px);

  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: $header_margin-bottom;

    @include media-max(xl) {
      margin-bottom: $header_max-xl_margin-bottom;
    }

    @include media-max(sm) {
      margin-bottom: $header_max-sm_margin-bottom;
    }
  }

  &__pagination,
  &__next-to-btn {
    display: none;
  }

  @include media-min(sm) {
    &__bottom {
      margin-top: auto;
    }

    &__pagination {
      display: block;
    }
  }

  @include media-min(lg) {
    &__next-to-btn {
      display: block;
    }

    &__bottom {
      margin-top: auto;
      display: flex;
      align-items: flex-end;
    }

    &__next-to-btn {
      z-index: 1;
    }

    &__pagination,
    &__next-to-btn {
      flex-shrink: 0;
    }

    &__pagination {
      margin-right: $pagination_margin-right_min-xl;
    }
  }

  @media (orientation: landscape) and (max-height: 414px) {
    &__pagination {
      display: none;
    }
  }
}
