.hero {
  $this_padding-top: $header_height;

  $header_margin-top: rem(45px);
  $header_margin-top_min-md: rem(80px);
  $header_margin-top_min-xl: rem(90px);

  $header_margin-bottom: rem(60px);
  $header_margin-bottom_min-md: rem(80px);

  padding-top: $this_padding-top;

  &__header {
    margin-top: $header_margin-top;
    margin-bottom: $header_margin-bottom;
  }

  @include media-min(md) {
    &__header {
      margin-top: $header_margin-top_min-md;
      margin-bottom: $header_margin-bottom_min-md;
    }
  }

  @include media-min(xl) {
    &__header {
      margin-top: $header_margin-top_min-xl;
    }
  }
}
