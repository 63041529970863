.products-use-cases-statistic {
  $this_padding-bottom: rem(2px);

  padding-bottom: $this_padding-bottom;
  overflow-x: auto;

  &__table {
    min-width: 1360px;

    @include media-max(sm) {
      display: none;
    }
  }

  &__accordions {
    @include media-min(sm) {
      display: none;
    }
  }
}
