.maps {
  $item_margin-bottom: rem(53px);
  $list_min-md_margin-x: -$maps-item_min-md_padding-x;

  flex: 0 0 auto;
  overflow: hidden;

  &__item:not(:last-child) {
    margin-bottom: $item_margin-bottom;
  }

  @include media-min(md) {
    &__item:not(:last-child) {
      margin-bottom: 0;
    }

    &__list {
      margin-left: $list_min-md_margin-x;
      margin-right: $list_min-md_margin-x;
    }
  }
}
