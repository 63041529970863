.facilities {
  $list-container_margin-bottom: rem(70px);
  $list-container_min-md_margin-bottom: rem(90px);
  $list-container_min-xl_margin-bottom: rem(105px);

  $loader-container_margin-bottom: rem(80px);
  $loader-container_min-md_margin-bottom: rem(110px);
  $loader-container_min-xl_margin-bottom: rem(105px);

  &__body-inner {
    display: flex;
    flex-direction: column;
  }

  &__list-container {
    margin-bottom: $list-container_margin-bottom;
  }

  &__loader-container {
    margin-bottom: $loader-container_margin-bottom;
    text-align: center;
  }

  &__loader {
    display: none;
  }

  @include media-min(md) {
    &__list-container {
      margin-bottom: $list-container_min-md_margin-bottom;
    }

    &__loader-container {
      margin-bottom: $loader-container_min-md_margin-bottom;
    }
  }

  @include media-min(xl) {
    &__list-container {
      margin-bottom: $list-container_min-xl_margin-bottom;
    }

    &__loader-container {
      margin-bottom: $loader-container_min-xl_margin-bottom;
    }
  }
}
