// body

.body {
  $this_background-color: $background-color-base;
  $this_font-family: $font-family-tt-commons;
  $this_line-height: $line-height-lg;
  $this_color: $text-primary-color;

  position: relative;

  font-family: $this_font-family;
  line-height: $this_line-height;
  color: $this_color;
  background-color: $this_background-color;

  overflow-y: scroll;

  &_prevent-scroll {
    overflow-y: hidden;
  }

  &_menu-opened {
    overflow-y: scroll;

    .main {
      max-height: 100vh;
      overflow: hidden;
    }
  }

  &_hide-main {
    overflow-y: scroll;

    .main {
      max-height: 100vh;
      overflow: hidden;
    }
  }
}
