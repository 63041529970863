.background {
  $red_background-color: $color-red;
  $blue_background-color: $color-blue;
  $green_background-color: $color-green;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: -1;

  &_red {
    background-color: $red_background-color;
  }

  &_blue {
    background-color: $blue_background-color;
  }

  &_green {
    background-color: $green_background-color;
  }
}
