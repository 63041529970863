.manufacture-slide-switcher {
  @include media-max(sm) {
    display: flex;
    justify-content: flex-end;
  }

  &__buttons {
    @include media-max(sm) {
      margin: 0;
    }
  }

  &__timer {
    @include media-max(sm) {
      display: none;
    }
  }
}
