.control-cabinets-header {
  $this_background-color: $header_background-color;
  $this_blur: $header_background-blur;
  $this_z-index: 100;

  $top_margin: (rem(35px) + $header_height) 0 rem(65px);
  $top_max-xl_margin: rem(44px) 0 rem(48px);
  $top_max-sm_margin: rem(35px) 0 rem(30px);

  $title_max-xl_font-size: rem(30px);
  $title_max-sm_font-size: rem(28px);

  // position: sticky;
  // top: 0;

  background-color: $this_background-color;
  backdrop-filter: blur($this_blur);

  z-index: $this_z-index;

  @include media-max(xl) {
    position: static;
  }

  &__details {
    margin-top: rem(58px);
  }

  &__top {
    margin: $top_margin;

    @include media-max(xl) {
      margin: $top_max-xl_margin;
    }

    @include media-max(sm) {
      margin: $top_max-sm_margin;
    }
  }

  &__body {
    @include media-max(md) {
      display: flex;
      overflow-x: scroll;
    }
  }

  &__body-inner {
    @include media-max(md) {
      flex: 1 1 220vw;
    }

    @include media-max(md) {
      flex-basis: 260vw;
    }
  }

  &__title {
    @include media-max(xl) {
      font-size: $title_max-xl_font-size;
    }

    @include media-max(sm) {
      font-size: $title_max-sm_font-size;
    }
  }

  &__title-text {
    display: inline-block;
  }

  @include media-min(xl) {
    &__navs:hover .control-cabinets-products-nav {
      // display: block;
      // animation-name: showSubNav;
      visibility: visible;
      opacity: 1;
    }

    // @keyframes showSubNav {
    //   0% {

    //   }
    // }
  }
}
