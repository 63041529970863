.control-cabinets-header-accordion {
  $toggle_margin-right: rem(20px);

  &__header-inner {
    display: flex;

    align-items: center;

    padding-bottom: 0;
  }

  &__body-inner {
    padding: 0;
  }

  &__toggle {
    margin-right: $toggle_margin-right;
  }
}
