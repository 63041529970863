.our-facilities-facility {
  $this_margin-bottom: rem(50px);
  $this_min-lg_margin-bottom: rem(15px);

  $image-container_margin-bottom: rem(21px);
  $name-container_margin-bottom: rem(6px);
  $place-container_margin-bottom: rem(21px);

  $image_min-xl_max-width: rem(340px);

  $image_min-sm_height: rem(142px);
  $image_min-xl_height: rem(115px);

  $name_font-size: rem(20px);
  $name_font-family: $font-family-gl-interstate;
  $name_line-height: 1;

  $place_font-size: rem(14px);
  $place_font-family: $font-family-anonymous-pro;
  $place_line-height: $line-height-sm;

  $link-icon_size: em(18px);
  $link-icon_color: $primary-color;

  $logo-container_margin-bottom: rem(27px);

  box-shadow: inset 0 ($block-divider_weight) 0 0 $block-divider_color;

  &:not(:last-child) {
    margin-bottom: $this_margin-bottom;
  }

  &__image-container {
    margin-bottom: $image-container_margin-bottom;
  }

  &__name-container {
    margin-bottom: $name-container_margin-bottom;
  }

  &__place-container {
    margin-bottom: $place-container_margin-bottom;
  }

  &__link-container {
    display: flex;
    justify-content: flex-end;
    align-self: flex-start;
  }

  &__link {
    display: inline-block;
  }

  &__link-icon {
    width: $link-icon_size;
    height: $link-icon_size;
    color: $link-icon_color;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__name {
    font-family: $name_font-family;
    font-size: $name_font-size;
    line-height: $name_line-height;
  }

  &__place {
    font-family: $place_font-family;
    font-size: $place_font-size;
    line-height: $place_line-height;
  }

  @include media-min(sm) {
    box-shadow: inset 0 (-$block-divider_weight) 0 0 $block-divider_color;

    &:not(:last-child) {
      margin-bottom: $this_min-lg_margin-bottom;
    }

    &__image-container {
      margin-bottom: 0;
    }

    &__logo-container {
      margin-bottom: $logo-container_margin-bottom;
    }

    &__image {
      height: $image_min-sm_height;
    }
  }

  @include media-min(lg) {
    padding: 0;
  }

  @include media-min(xl) {
    &__image {
      max-height: $image_min-xl_height;
      max-width: none;
    }
  }
}
