.product-nav-section-nav {
  $sub-nav_margin-bottom: rem(13px);
  $sub-nav_min-md_margin-bottom: rem(20px);

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__sub-nav:not(:last-child) {
    margin-bottom: $sub-nav_margin-bottom;
  }

  @include media-min(md) {
    &__sub-nav:not(:last-child) {
      margin-bottom: $sub-nav_min-md_margin-bottom;
    }
  }
}
