.reviews-review-author {
  $this_margin-bottom: rem(52px);

  $avatar-container_margin-bottom: rem(50px);
  $avatar-container_margin-bottom_min-md: rem(60px);
  $avatar-container_margin-bottom_min-xl: rem(76px);

  $avatar_width: rem(150px);
  $avatar_width_min-md: rem(135px);
  $avatar_width_min-xl: rem(180px);

  $avatar_height: rem(105px);
  $avatar_height_min-md: rem(95px);
  $avatar_height_min-xl: rem(125px);

  $avatar-after_border: 1px dashed $color-gray-l-51;

  $full-name_font-size: rem(20px);
  $full-name-container_margin-bottom: rem(9px);
  $full-name-container_margin-bottom_min-md: rem(5px);

  $position_min-md_font-size: rem(15px);

  margin-bottom: $this_margin-bottom;

  &__avatar-container {
    margin-bottom: $avatar-container_margin-bottom;
  }

  &__full-name-container {
    margin-bottom: $full-name-container_margin-bottom;
  }

  &__full-name {
    font-size: $full-name_font-size;
  }

  &__avatar {
    position: relative;
    width: $avatar_width;
    height: $avatar_height;
    display: flex;

    &:after {
      content: "";

      position: absolute;

      top: 20%;
      left: 25%;

      width: 100%;
      height: 100%;

      border: $avatar-after_border;

      z-index: -1;
    }
  }

  &__avatar-inner {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &__avatar-image {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  @include media-min(md) {
    &__avatar-container {
      margin-bottom: $avatar-container_margin-bottom_min-md;
    }

    &__full-name-container {
      margin-bottom: $full-name-container_margin-bottom_min-md;
    }

    &__position {
      font-size: $position_min-md_font-size;
    }

    &__avatar {
      width: $avatar_width_min-md;
      height: $avatar_height_min-md;
    }
  }

  @include media-min(xl) {
    &__avatar-container {
      margin-bottom: $avatar-container_margin-bottom_min-xl;
    }

    &__avatar {
      width: $avatar_width_min-xl;
      height: $avatar_height_min-xl;
    }
  }
}
