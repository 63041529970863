// Текст

$text-secondary_font-family: $font-family-anonymous-pro;
$text-secondary_font-size: rem(15px);
$text-secondary_line-height: $line-height-sm;

$text-tertiary_font-family: $font-family-anonymous-pro;
$text-tertiary_color: $color-gray-chateau;
$text-tertiary_font-size: rem(17px);
$text-tertiary_line-height: $line-height-sm;

.text {
  $caption_font-size: rem(15px);
  $caption_min-sm_font-size: rem(17px);
  $caption_line-height: $line-height-sm;
  $caption_color: $text-secondary-color;
  $caption_font-family: $font-family-anonymous-pro;

  $accent_font-size: rem(15px);
  $accent_min-lg_font-size: rem(20px);
  $accent_font-family: $font-family-gl-interstate;

  $primary_color: $primary-color;

  $description_font-family: $font-family-anonymous-pro;
  $description_color: $text-secondary-color;
  $description_font-size: rem(15px);
  $description_font-size_min-md: rem(17px);
  $description_line-height: $line-height-sm;

  $secondary_color: $text-secondary-color;

  &_caption {
    font-size: $caption_font-size;
    line-height: $caption_line-height;
    color: $caption_color;
    font-family: $caption_font-family;

    transition: $theme-text-color-transition;

    .light & {
      color: $theme-light-text-secondary-color;
    }
  }

  &_accent {
    font-size: $accent_font-size;
    font-family: $accent_font-family;
  }

  &_primary {
    color: $primary_color;
  }

  &_secondary,
  &_tertiary {
    font-family: $text-secondary_font-family;
    line-height: $text-secondary_line-height;
  }

  &_secondary {
    color: $secondary_color;
  }

  &_tertiary {
    color: $text-tertiary_color;
    font-size: $text-tertiary_font-size;
  }

  &_secondary-dark {
    color: $color-gray-l-38;
  }

  &_lowercase {
    text-transform: lowercase;
  }

  &_italic {
    font-style: italic;
  }

  &_bold {
    font-weight: $font-weight-bold;
  }

  &_mono {
    font-family: $font-family-anonymous-pro;
  }

  &_sm {
    font-size: rem(14px);
  }

  &_description {
    font-family: $description_font-family;
    color: $description_color;
    font-size: $description_font-size;
    line-height: $description_line-height;

    .light & {
      color: $theme-light-text-secondary-color;
      transition-duration: $theme-text-color-transition;
    }
  }

  &_not-empty:empty:after {
    content: " ";
    white-space: pre;
  }

  @include media-min(sm) {
    &_caption {
      font-size: $caption_min-sm_font-size;
    }
  }

  @include media-min(md) {
    &_description {
      font-size: $description_font-size_min-md;
    }
  }

  @include media-min(lg) {
    &_accent {
      font-size: $accent_min-lg_font-size;
    }
  }
}
