// Иконка

$icon-link-arrow-up-bold_margin-bottom: em(3.3px);
$icon-link-arrow-up-bold_margin-left: em(4px);

.icon {
  $this-size: em(16px);

  $link-arrow_size: em(8px);

  $link-arrow-up-bold_margin-bottom: $icon-link-arrow-up-bold_margin-bottom;
  $link-arrow-up-bold_margin-left: $icon-link-arrow-up-bold_margin-left;

  position: relative;

  display: inline-block;

  width: $this-size;
  height: $this-size;

  &_link-arrow-up-bold,
  &_link-arrow-up {
    width: $link-arrow_size;
    height: $link-arrow_size;
    margin-left: $link-arrow-up-bold_margin-left;
  }

  &_link-arrow-up-bold {
    // поднимает иконку чуть выше, чтобы она была ровно на уровне текста
    margin-bottom: $link-arrow-up-bold_margin-bottom;
  }
}
