$color-gray-l-20: #333333;
$color-gray-l-23: #3b3b3b;
$color-gray-l-26: #424242;
$color-gray-l-34: #575757;
$color-gray-l-38: #616161;
$color-gray-l-51: #828282;
$color-gray-l-52: #858585;
$color-gray-l-61: #999b9d;
$color-gray-l-77: #c4c4c4;
$color-gray-l-88: #e0e0e0;
$color-gray-l-95: #f2f2f2;
$color-gray-l-96: #f5f5f5;

$color-gray-chateau: #9a9c9e;

$color-red: #c81006;
$color-blue: #0625c8;
$color-green: #00bc08;
$color-white: #fff;
$color-black: #000;

$primary-color: $color-gray-l-23;
$secondary-color: $color-gray-chateau;

$background-color-base: $color-gray-l-95;

// text

$text-primary-color: $primary-color;
$text-secondary-color: $secondary-color;

// theme light
$theme-light-primary-color: $color-white;

$theme-light-text-primary-color: $color-white;
$theme-light-text-secondary-color: transparentize($theme-light-text-primary-color, 0.4);
