$section-hero-header-container_padding-top: rem(45px);
$section-hero-header-container_padding-top_min-md: rem(80px);
$section-hero-header-container_padding-top_min-xl: rem(90px);

.section-hero-header {
  $container_padding-top: $section-hero-header-container_padding-top;
  $container_padding-top_min-md: $section-hero-header-container_padding-top_min-md;
  $container_padding-top_min-xl: $section-hero-header-container_padding-top_min-xl;

  $container_padding-bottom: rem(80px);
  $container_padding-bottom_min-md: rem(120px);

  $caption-container_margin-top: rem(35px);

  $caption_font-size: rem(15px);
  $caption_font-size_min-md: rem(14px);
  $caption_color: $color-gray-l-52;

  &__container {
    padding-top: $container_padding-top;
    padding-bottom: $container_padding-bottom;
  }

  &__title-container + &__caption-container {
    margin-top: $caption-container_margin-top;
  }

  &__caption {
    font-size: $caption_font-size;
    color: $caption_color;
  }

  @include media-min(sm) {
    &__title-container + &__caption-container {
      margin-top: 0;
    }
  }

  @include media-min(md) {
    &__container {
      padding-top: $container_padding-top_min-md;
      padding-bottom: $container_padding-bottom_min-md;
    }

    &__caption {
      font-size: $caption_font-size_min-md;
    }
  }

  @include media-min(xl) {
    &__container {
      padding-top: $container_padding-top_min-xl;
    }
  }
}
