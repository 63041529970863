.footer-nav {
  $title_font-size: rem(16px);
  $title_font-weight: $font-weight-regular;
  $title_font-family: $font-family-anonymous-pro;
  $title_line-height: $line-height-sm;

  $link_font-family: $font-family-gl-interstate;
  $link_padding-bottom: rem(5px);

  $link-hover-underline_weight: rem(3px);
  $link-hover-underline_color: $color-gray-l-23;
  $link-hover-underline_transition: all 0.35s cubic-bezier(0.87, 0, 0.13, 1);

  $item_margin-bottom: rem(10px);

  $item_max-sm_margin-bottom: rem(15px);

  $title_max-xl_margin-bottom: rem(26px);
  $title_max-sm_margin-bottom: rem(30px);

  &__title {
    font-size: $title_font-size;
    font-weight: $title_font-weight;
    font-family: $title_font-family;
    line-height: $title_line-height;
  }

  &__item:not(:last-child) {
    margin-bottom: $item_margin-bottom;
  }

  &__link {
    position: relative;

    padding-bottom: $link_padding-bottom;

    font-family: $link_font-family;

    @include hover-underline;
  }

  @include media-max(xl) {
    &__title {
      margin-bottom: $title_max-xl_margin-bottom;
    }
  }

  @include media-max(sm) {
    &__title {
      margin-bottom: $title_max-sm_margin-bottom;
    }

    &__item:not(:last-child) {
      margin-bottom: $item_max-sm_margin-bottom;
    }

    &__link {
      font-size: rem(14px);
    }
  }
}
