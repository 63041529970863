.advantages-aside {
  $header_margin-bottom: rem(115px);

  &__header {
    margin-bottom: $header_margin-bottom;

    @include media-max(lg) {
      display: none;
    }
  }
}
