@function get-background-dashed-border(
  $weight: 1px,
  $dash: (
    width: 2px,
    space: 5px,
  ),
  $positions: top right bottom left,
  $color: currentcolor
) {
  $result: ();

  $dash-width: map-get($dash, width);
  $dash-space: map-get($dash, space);
  $dash-full-width: $dash-width + $dash-space;

  @each $position in $positions {
    $gradient-angle: 0deg;
    $background-image-repeat: repeat-y;
    $background-size: $weight $dash-full-width;
    $background-position: if(
      $position == bottom or $position == right,
      100% 100%,
      0 0
    );

    @if ($position == top or $position == bottom) {
      $gradient-angle: 90deg;
      $background-image-repeat: repeat-x;
      $background-size: $dash-full-width $weight;
    }

    $result: append(
      $result,
      linear-gradient($gradient-angle, $color $dash-width, transparent 0)
        $background-position/$background-size
        $background-image-repeat
        border-box,
      comma
    );
  }

  @return $result;
}
