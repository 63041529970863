// Список

$list-item-inner_padding-left: rem(20px);

$list-item_margin-bottom: rem(10px);
$list-item_margin-bottom_min-md: rem(7px);

.list {
  $text_font-size: rem(17px);
  $text_font-size_min-md: rem(16px);

  $item_margin-bottom: $list-item_margin-bottom;
  $item_margin-bottom_min-md: $list-item_margin-bottom_min-md;

  margin: 0;
  padding: 0;

  list-style: none;

  &__text {
    font-size: $text_font-size;
  }

  &__item:not(:last-child) {
    margin-bottom: $list-item_margin-bottom;
  }

  &__item-inner {
    position: relative;
    padding-left: $list-item-inner_padding-left;

    &:before {
      @include list-item-marker;
      @include list-item-marker-disc;
    }
  }

  &_style-square &__item-inner {
    &:before {
      @include list-item-marker-square;
    }
  }

  @include media-min(md) {
    &__text {
      font-size: $text_font-size_min-md;
    }

    &__item:not(:last-child) {
      margin-bottom: $list-item_margin-bottom_min-md;
    }
  }
}
