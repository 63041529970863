$copyright-right_margin-bottom: rem(40px);

.copyright {
  $this_padding-y: rem(27px) 0 rem(15px);
  $this_min-md_padding-y: rem(25px) 0 rem(12px);
  $this_min-xl_padding-y: rem(18px) 0 rem(10px);

  $right_margin-bottom: $copyright-right_margin-bottom;

  padding: $this_padding-y;

  &__left,
  &__right {
    align-self: flex-end;
  }

  &__right {
    order: 0;

    margin-bottom: $right_margin-bottom;
  }

  &__left {
    display: flex;
    align-items: flex-end;

    order: 1;
  }

  @include media-max(413px) {
    &__left,
    &__right {
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  @include media-min(414px) {
    &__right {
      margin-bottom: 0;

      text-align: right;

      order: 1;
    }

    &__left {
      order: 0;
    }
  }

  @include media-min(sm) {
    &__left {
      order: 0;
    }

    &__right {
      order: 1;

      margin-bottom: 0;
    }
  }

  @include media-min(md) {
    padding: $this_min-md_padding-y;
  }

  @include media-min(xl) {
    padding: $this_min-xl_padding-y;
  }
}
