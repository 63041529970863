.home-hero-background-slider {
  height: 100%;

  &__slides {
    margin: 0;
    padding: 0;

    list-style: none;
  }
}
