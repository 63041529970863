.home-hero-product-sub-types {
  $this_margin-bottom: rem(36px);
  $this_margin-bottom_min-sm: rem(84px);
  $this_margin-bottom_min-lg: 0;

  $header_margin-bottom: rem(25px);
  $header_margin-bottom_min-sm: rem(22px);
  $header_margin-bottom_min-lg: rem(25px);

  $title_font-size: rem(30px);
  $title_font-size_min-sm: rem(60px);
  $title_font-size_min-xl: rem(70px);

  $title-icon_margin-bottom: em(3.3px);

  $header_height: 2em;

  margin-bottom: $this_margin-bottom;

  &__header {
    margin-bottom: $header_margin-bottom;
    height: $header_height;

    display: flex;
    align-items: flex-end;
  }

  &__title-inner {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }

  &__title-content {
    display: inline-block;
  }

  &__title-text {
    white-space: pre-wrap;
    white-space: break-spaces;
  }

  &__title {
    display: none;
    vertical-align: bottom;
    font-size: $title_font-size;

    &.active {
      display: inline-block;
    }
  }

  &__title-icon {
    margin-bottom: $title-icon_margin-bottom;
  }

  @include media-min(410px) {
    &__header {
      height: auto;
    }
  }

  @include media-min(sm) {
    margin-bottom: $this_margin-bottom_min-sm;

    &__header {
      margin-bottom: $header_margin-bottom_min-sm;
      height: $header_height;
    }

    &__title {
      font-size: $title_font-size_min-sm;
    }
  }

  @include media-min(lg) {
    margin-bottom: $this_margin-bottom_min-lg;

    &__header {
      margin-bottom: $header_margin-bottom_min-lg;
    }
  }

  @include media-min(xl) {
    &__title {
      font-size: $title_font-size_min-xl;
    }
  }

  @include media-between(991px, 1400px) {
    &__header,
    &__title {
      font-size: rem(50px);
    }
  }
}
