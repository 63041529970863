.home-hero-background-slider-slide {
  $image-wrapper_max-height: 50vh;
  $image-wrapper_max-height_min-xxs: 75vh;
  $image-wrapper_max-height_min-sm: 65vh;

  position: relative;
  overflow: hidden;

  // remove flickering on slide
  transform: translateZ(0);

  &__inner {
    width: 100%;
    height: 100%;

    backface-visibility: hidden;

    will-change: transform;
  }

  &__image {
    max-height: 100%;

    backface-visibility: hidden;
    will-change: transform;
  }

  &__image-wrapper {
    position: absolute;

    bottom: 0;
    left: 0;

    transform: translate(-31%, 15%) translateZ(0);
    pointer-events: none;

    display: flex;
    align-items: flex-end;

    // percentage(90px / 485px)

    height: 60vh;
  }

  @include media-min(375px) {
    &__image-wrapper {
      height: 50vh;
    }
  }

  @include media-min(md) {
    &__image-wrapper {
      // height: auto;
      height: 40vh;
    }
    // &__image-wrapper {
    //   width: vh(598px / 1025px);
    // }
  }

  @include media-min(lg) {
    &__image-wrapper {
      // height: auto;
      height: 90vh;
      // max-height: 50vh;
      right: 0;
      // width: 100%;
      transform: translate(10%, 0);
      // transform: translate(-15%, 10%);
    }
  }

  @include media-min(xl) {
    &__image-wrapper {
      // transform: translate(0, 15%);
      transform: translate(0, 5%);
    }
  }

  @include media-min(xxl) {
    // &__image-wrapper {
    //   transform: translate(0, percentage(195px / 895px)) translateZ(0);
    // }
  }

  @media (orientation: landscape) and (max-height: 414px) {
    &__image-wrapper {
      height: 70vh;
      transform: translate(-31%, 25%) translateZ(0);
    }
  }
}
