.reviews-review {
  $text_font-family: $font-family-anonymous-pro;
  $text_line-height: 1.28;
  $text_font-size: rem(20px);
  $text_font-size_min-md: rem(25px);
  $text_font-size_min-xl: rem(38px);

  &__content {
    font-family: $text_font-family;
    line-height: $text_line-height;
    font-size: $text_font-size;
    max-width: 1500px;
  }

  @include media-min(md) {
    &__content {
      font-size: $text_font-size_min-md;
    }
  }

  @include media-min(xl) {
    &__content {
      font-size: $text_font-size_min-xl;
    }
  }
}
