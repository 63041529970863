.header-contact {
  $link_letter-spacing: 0.02em;
  $link_padding-y: rem(4px);
  $link_lin-height: $line-height-none;

  &__content {
    padding: $link_padding-y 0;

    letter-spacing: $link_letter-spacing;
    line-height: $link_lin-height;
  }
}
