.contacts-card {
  $header_margin-bottom: rem(50px);

  $link_font-family: $font-family-gl-interstate;
  $link_font-weight: $font-weight-bold;
  $link_font-size: rem(16px);

  &__header {
    margin-bottom: $header_margin-bottom;
  }

  &__body {
    margin: auto 0 0 0;
  }

  &__link {
    display: block;
    font-family: $link_font-family;
    font-weight: $link_font-weight;
    font-size: $link_font-size;
    color: inherit;
    text-decoration: none;
  }
}
