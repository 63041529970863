.footer-company-info {
  $this_margin-bottom: rem(60px);
  $this_margin-bottom_min-sm: rem(115px);
  $this_margin-bottom_min-xl: 0;

  $contacts_margin-bottom: rem(61px);
  $contacts_margin-bottom_min-sm: 0;
  $contacts_margin-bottom_min-xl: rem(120px);

  $contact-not-last_margin-bottom: rem(20px);

  margin-bottom: $this_margin-bottom;

  &__contacts {
    margin-bottom: $contacts_margin-bottom;
  }

  &__contact:not(:last-child) {
    margin-bottom: $contact-not-last_margin-bottom;
  }

  &__contact .contact__content {
    vertical-align: bottom;
  }

  &__contact-link {
    &:after {
      bottom: -5px;
    }
  }

  @include media-min(sm) {
    margin-bottom: $this_margin-bottom_min-sm;

    &__contacts {
      margin-bottom: $contacts_margin-bottom_min-sm;
    }

    &__general {
      align-items: flex-end;
      display: flex;
    }
  }

  @include media-min(xl) {
    margin-bottom: $this_margin-bottom_min-xl;

    &__contacts {
      margin-bottom: $contacts_margin-bottom_min-xl;
    }

    &__general {
      align-items: flex-start;
    }

    &__contact:last-child {
      margin-bottom: $contact-not-last_margin-bottom;
    }
  }
}
