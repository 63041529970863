$contacts-requisites-item_min-md_margin-bottom: rem(34px);
$contacts-requisites-item_min-xl_margin-bottom: rem(38px);

.contacts-requisites {
  $header_min-md_margin-bottom: rem(43px);

  $item_margin-bottom: $contacts-requisites-item_min-md_margin-bottom;
  $item_min-xl_margin-bottom: $contacts-requisites-item_min-xl_margin-bottom;

  &__header {
    display: none;
  }

  &__item:not(:last-child) {
    margin-bottom: $item_margin-bottom;
  }

  &__list:not(:last-child) {
    margin-bottom: rem(90px);
  }

  @include media-min(md) {
    &__header {
      display: block;
      margin-bottom: $header_min-md_margin-bottom;
    }
  }

  @include media-min(xl) {
    &__header {
      align-self: flex-start;
    }

    &__item {
      margin-bottom: $item_min-xl_margin-bottom;
    }
  }
}
