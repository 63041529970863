.products-use-cases-statistic-table {
  $this: &;
  $cell_border-color: $color-gray-l-34;
  $cell-head_padding: rem(15px) rem(5px);
  $cell-body_height: rem(135px);
  $cell-body_max-sm_height: rem(71px);
  $first-cell-body_width: percentage(220px / 1360px);
  $mobile-first-cell-body_padding: 0 rem(15px) rem(10px) rem(5px);

  width: 100%;
  border-collapse: collapse;
  user-select: none;

  &__body &__row:first-child &__cell-inner {
    margin-top: rem(30px);
  }

  &_mobile &__body &__row:first-child &__cell-inner {
    margin-top: 0;
  }

  &__body &__cell,
  &__head &__cell-inner {
    overflow: hidden;
  }

  &__cell-inner {
    overflow: hidden;
  }

  &__cell {
    padding: 0;
    border-bottom: 1px solid $cell_border-color;
    font-family: $font-family-gl-interstate;
    line-height: $line-height-none;

    &_head {
      position: relative;
      padding: $cell-head_padding;

      &:not(:last-child) {
        padding-right: rem(60px);
      }

      &:not(:first-child):before {
        content: "";
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 1px;
        height: 15px;
        background-color: $color-gray-l-23;
      }

      @include media-max(xl) {
        &:first-child {
          border-bottom: none;
        }
      }
    }

    &_body {
      vertical-align: bottom;

      #{$this}__cell-inner {
        display: flex;
        align-items: flex-end;
        height: $cell-body_height;
      }

      &:first-child {
        width: $first-cell-body_width;
        padding-bottom: rem(10px);

        border-bottom: 1px solid $color-gray-l-88;
      }
    }
  }

  &_mobile &__cell {
    &_body {
      width: 50%;

      #{$this}__cell-inner {
        height: $cell-body_max-sm_height;
      }

      &:first-child {
        border-bottom: 1px solid $color-gray-l-88;
        padding: $mobile-first-cell-body_padding;
      }
    }
  }
}
