.products-use-cases {
  $this_padding: rem(150px) 0 rem(120px) 0;

  $header_margin-bottom: rem(70px);

  $description_margin-bottom: rem(85px);

  $this_max-xl_padding: rem(146px) 0 rem(105px);
  $this_max-sm_padding: rem(120px) 0 rem(60px);

  $header_max-xl_margin-bottom: rem(32px);
  $header_max-sm_margin-bottom: rem(28px);

  $description_max-xl_margin-bottom: rem(100px);
  $description_max-sm_margin-bottom: rem(57px);

  padding: $this_padding;

  @include media-max(xl) {
    padding: $this_max-xl_padding;
  }

  @include media-max(sm) {
    padding: $this_max-sm_padding;
  }

  &__header {
    margin-bottom: $header_margin-bottom;

    @include media-max(xl) {
      margin-bottom: $header_max-xl_margin-bottom;
    }

    @include media-max(sm) {
      margin-bottom: $header_max-sm_margin-bottom;
    }
  }

  &__description {
    margin-bottom: $description_margin-bottom;

    @include media-max(xl) {
      margin-bottom: $description_max-xl_margin-bottom;
    }

    @include media-max(sm) {
      margin-bottom: $description_max-sm_margin-bottom;
    }
  }
}
