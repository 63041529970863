.next-to-btn {
  @import "./vars";

  white-space: nowrap;

  &__inner {
    display: flex;
  }

  &__dir-label,
  &__button-label {
    display: inline-block;
  }

  &__dir {
    display: inline-block;
    transition: $theme-text-color-transition;

    .light & {
      color: $theme-light-text-secondary-color;
    }
  }

  &__dir-container {
    margin-right: $next-to-btn-dir-container_margin-right;
    flex: 0 0 $next-to-btn-dir-container_flex-basis;
    text-align: right;
  }

  &__link-icon {
    transform: rotate(90deg);
  }

  &__button {
    font-weight: $font-weight-bold;

    &_active {
      display: block;
    }
  }
}
