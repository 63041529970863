.transition-item {
  display: inline-block;
  vertical-align: bottom;

  & > * {
    will-change: transform;
  }

  &.slide-up-enter > * {
    transform: translateY(100%);
  }

  &.slide-up-enter-active > * {
    transform: translateY(0%);
  }

  &.slide-up-exit > * {
    transform: translateY(0%);
  }

  &.slide-up-exit-active > * {
    transform: translateY(-100%);
  }

  &.slide-up-enter-active > *,
  &.slide-up-exit-active > * {
    transition: transform 0.5s;
  }

  &.slide-up-enter-active,
  &.slide-up-exit-active {
    overflow: hidden;
  }

  &.slide-up-enter-active > * {
    transition-timing-function: ease-out;
  }

  &.slide-up-exit-active > * {
    transition-timing-function: ease-in;
  }

  //
  &.fade-enter > * {
    opacity: 0;
  }

  &.fade-enter-active > * {
    opacity: 1;
  }

  &.fade-exit > * {
    opacity: 1;
  }

  &.fade-exit-active > * {
    opacity: 0;
  }

  &.fade-enter-active > *,
  &.fade-exit-active > * {
    transition: opacity 0.5s;
  }

  &.fade-enter-active > * {
    transition-timing-function: ease-out;
  }

  &.fade-exit-active > * {
    transition-timing-function: ease-in;
  }
}
