.our-facilities-header {
  $title-wrapper_margin-bottom: rem(49px);
  $title-wrapper_min-md_margin-bottom: rem(50px);
  $title-wrapper_min-lg_margin-bottom: rem(61px);

  $view-all-wrapper_margin-bottom: rem(49px);
  $view-all-wrapper_min-lg_margin-bottom: rem(49px);

  $description-wrapper_margin-bottom: rem(49px);

  &__title-wrapper {
    margin-bottom: $title-wrapper_margin-bottom;
  }

  &__view-all-wrapper {
    display: flex;

    margin-bottom: $view-all-wrapper_margin-bottom;

    align-items: flex-end;
    justify-content: flex-end;
  }

  &__description-wrapper {
    margin-bottom: $description-wrapper_margin-bottom;
  }

  @include media-max(450px) {
    &__title-wrapper {
      margin-bottom: rem(24px);
    }

    &__title-wrapper,
    &__view-all-wrapper {
      display: block;

      max-width: 100%;
      flex-basis: 100%;
    }
  }

  @include media-min(md) {
    &__title-wrapper {
      margin-bottom: $title-wrapper_min-md_margin-bottom;
    }

    &__view-all-wrapper {
      margin-bottom: 0;

      display: block;

      text-align: right;
    }

    &__description-wrapper {
      margin-bottom: 0;
    }

    &__view-all {
      line-height: 1;
    }
  }

  @include media-min(lg) {
    &__view-all-wrapper {
      display: flex;

      margin-bottom: $view-all-wrapper_min-lg_margin-bottom;
    }

    &__favorite-works-counter {
      line-height: 1;
    }
  }
}
