.control-cabinets-products-nav {
  // variables
  $this_background-color: $color-gray-l-95;
  $this_blur: $header_background-blur;

  $list_margin: rem(24px) 0 rem(40px) 0;
  $list_max-xl_margin-top: rem(30px);
  $list_max-sm_margin-bottom: rem(54px);

  $list-item_margin-bottom: rem(2px);

  $list-item_max-xl_margin-bottom: rem(5px);

  $sub-nav_margin-bottom: rem(48px);

  $title_font-family: $font-family-gl-interstate;
  $title_font-weight: $font-weight-bold;
  $title_font-size: rem(14px);
  $title_line-height: $line-height-sm;
  $title_color: $secondary-color;

  $title_margin-bottom: rem(23px);

  $link_padding-y: rem(4px);
  $link_font-family: $font-family-anonymous-pro;
  $link_font-size: rem(14px);
  $link_line-height: $line-height-sm;
  $link_color: $color-gray-l-34;
  $link_transition: $transition-medium-ease-out;

  $link_max-xl_padding-y: rem(5px);
  $link_max-xl_font-size: rem(15px);

  $link-active_color: $primary-color;
  $link-active_font-weight: $font-weight-bold;
  $link-active_translate-x: $list-item-inner_padding-left;

  // styles

  background-color: $this_background-color;
  // backdrop-filter: blur($this_blur);

  transition: all 0.4s;

  &__sub-nav {
    padding: 0 rem(10px);
  }

  @include media-max(md) {
    padding: 0;
  }

  &__list {
    flex: 1 1 100%;

    padding: 0;
    padding: $list_margin;

    list-style: none;

    @include media-max(xl) {
      padding-top: $list_max-xl_margin-top;
    }

    @include media-max(md) {
      flex-wrap: nowrap;
    }

    @include media-max(sm) {
      padding-bottom: $list_max-sm_margin-bottom;
    }

    @include media-min(md) {
      margin-left: rem(-10px);
      margin-right: rem(-10px);
    }
  }

  &__sub-nav {
    @include media-max(md) {
      flex: 0 0 auto;
      width: 60vw;
      padding: 0 $container_padding-x_min-sm;

      &:last-child {
        width: 100vw;
      }
    }

    @include media-max(sm) {
      width: 80vw;
      padding: 0 $container_padding-x;
    }
  }

  &__list-item:not(:last-child) {
    margin-bottom: $list-item_margin-bottom;

    @include media-max(xl) {
      margin-bottom: $list-item_max-xl_margin-bottom;
    }
  }

  &__link {
    display: inline-flex;
    width: 100%;

    text-align: left;
    padding: $link_padding-y 0;

    font-family: $link_font-family;
    font-size: $link_font-size;
    line-height: $link_line-height;
    color: $link_color;
    text-decoration: none;

    transition: $link_transition;
    // фиксит проблему связанную с обрезанием текста в начале,
    // если какой-то из родительских контейнеров имеет overflow: hidden
    text-indent: 1px;

    @include media-max(xl) {
      padding: $link_max-xl_padding-y 0;

      font-size: $link_max-xl_font-size;
    }

    &:before {
      @include list-item-marker;
      @include list-item-marker-square;
      opacity: 0;
      transition: $link_transition;
    }

    &:hover,
    &_active {
      color: $link-active_color;
      text-shadow: 0.3px 0 0 $link-active_color;
    }

    &_active {
      position: relative;

      transform: translateX($link-active_translate-x);

      &:before {
        opacity: 1;

        transform: translateX(-$link-active_translate-x);
      }
    }
  }

  &__product-name {
    white-space: nowrap;
    flex: 0 0 auto;
  }

  &__product-type {
    margin-left: rem(10px);
  }

  @include media-min(xl) {
    position: absolute;

    opacity: 0;
    // display: none;
    visibility: hidden;
  }
}
