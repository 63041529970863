.scroll-progress {
  $this_height: rem(4px);

  height: $this_height;
  width: 100%;
  position: absolute;
  bottom: -$this_height;

  background-image: linear-gradient(0deg, $color-red, $color-red);
  background-repeat: no-repeat;
  background-size: 0;

  transition: background-size 0.4s;
  pointer-events: none;
}
