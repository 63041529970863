.label-with-icon {
  $icon_left-right: -(em(1.844px));

  white-space: nowrap;
  display: inline-block;

  &__label {
    display: inline;
    white-space: pre-wrap;
  }

  &__icon {
    position: relative;
    display: inline-block;

    &_left {
      right: $icon_left-right;
    }

    &_right {
      left: $icon_left-right;
    }
  }
}
