html,
body,
#root {
  height: 100%;
}

.wrapper,
.view {
  height: 100%;
}

.view {
  display: flex;
  flex-direction: column;
}
