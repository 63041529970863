.header-nav {
  height: 100%;

  &__item {
    display: flex;
    align-items: center;
  }

  &__link,
  &__list {
    height: 100%;
  }

  &__link {
    display: flex;
    align-items: center;

    @include hover-underline;

    &.active:after {
      display: none;
    }
  }

  @include media-max(xl) {
    display: none;
  }
}
