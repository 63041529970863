.not-found-hero {
  $container_padding-top: rem(35px);
  $container_padding-top_min-md: rem(40px);
  $container_padding-top_min-xl: rem(90px);

  $container_padding-bottom: rem(40px);
  $container_padding-bottom_min-md: rem(20px);
  $container_padding-bottom_min-xl: rem(60px);

  $header_margin-bottom: rem(45px);
  $header_margin-bottom_min-xl: 0;

  $title_font-size: rem(40px);
  $title_font-size_min-sm: rem(49px);
  $title_font-size_min-md: rem(70px);
  $title_font-size_min-xl: rem(69px);

  $title-container_margin-bottom: rem(30px);

  $description-container_margin-bottom_min-md: rem(30px);

  $alternative-container_margin-bottom_min-xl: rem(30px);

  height: 100vh;
  overflow: hidden;

  &__container,
  &__inner {
    height: 100%;
  }

  &__header {
    margin-bottom: $header_margin-bottom;
  }

  &__body {
    align-self: flex-end;
  }

  &__container {
    overflow-y: auto;
    padding-top: $container_padding-top;
    padding-bottom: $container_padding-bottom;
  }

  &__header-inner {
    display: flex;
    flex-direction: column;
  }

  &__description-container {
    display: none;
  }

  &__header-bottom {
    display: none;

    margin-top: auto;
  }

  &__title-container {
    margin-bottom: $title-container_margin-bottom;
  }

  &__title {
    font-size: $title_font-size;
  }

  &__control-cabinet-drawing {
    position: relative;

    width: 100%;
    height: 100%;

    max-width: 100%;

    overflow: visible;

    z-index: -1;

    user-select: none;
  }

  @include media-min(sm) {
    &__title {
      font-size: $title_font-size_min-sm;
    }
  }

  @include media-min(md) {
    &__container {
      padding-top: $container_padding-top_min-md;
      padding-bottom: $container_padding-bottom_min-md;
    }

    &__title {
      font-size: $title_font-size_min-md;
    }

    &__description-container {
      display: block;
      margin-bottom: $description-container_margin-bottom_min-md;
    }
  }

  @include media-min(lg) {
    &__container {
      padding-top: $container_padding-top_min-xl;
      padding-bottom: $container_padding-bottom_min-xl;
    }

    &__header,
    &__body,
    &__header-inner {
      height: 100%;
    }

    &__title {
      font-size: $title_font-size_min-xl;
    }

    &__header {
      margin-bottom: $header_margin-bottom_min-xl;
    }

    &__header-bottom {
      display: block;
    }

    &__alternative-container {
      margin-bottom: $alternative-container_margin-bottom_min-xl;
    }
  }
}
