.features-main {
  $header_margin-bottom: rem(157px);

  $header_max-xl_margin-bottom: rem(140px);
  $header_max-sm_margin-bottom: rem(100px);

  $product-not-last_margin-bottom: rem(160px);

  &__header {
    margin-bottom: $header_margin-bottom;

    @include media-max(xl) {
      margin-bottom: $header_max-xl_margin-bottom;
    }

    @include media-max(sm) {
      margin-bottom: $header_max-sm_margin-bottom;
    }
  }

  &__product:not(:last-child) {
    margin-bottom: $product-not-last_margin-bottom;
  }

  &__body {
    position: relative;

    display: none;

    &--mobile {
      display: block;
    }
  }

  &__body {
    &:not(&--mobile) {
      .features-main-product {
        & {
          margin-bottom: rem(80px);
        }

        &__description {
          position: absolute;
          top: 0;
          left: 0;

          visibility: hidden;
        }
      }
    }
  }

  @include media-min(sm) {
    &__body {
      display: block;

      &--mobile {
        display: none;
      }
    }
  }

  @media (orientation: landscape) and (max-height: 414px) {
    &__body {
      display: none;

      &--mobile {
        display: block;
      }
    }
  }
}
