// Лого

.header-logo {
  $icon_size: rem(25px);
  $label_margin-left: rem(12px);

  display: flex;
  align-items: center;

  &__link {
    display: inline-flex;
    align-items: center;

    text-decoration: none;
    font-family: $font-family-gl-interstate;
    text-transform: uppercase;
    font-size: rem(21px);
    color: inherit;
    font-weight: $font-weight-bold;
  }

  &__icon {
    width: $icon_size;
    height: $icon_size;
  }

  &__label {
    margin-left: $label_margin-left;
  }

  @include media-max(xs) {
    &__label {
      display: none;
    }
  }
}
