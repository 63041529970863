// Навигация

$nav-link_padding-y: rem(5px);

.nav {
  $this: &;

  $item_margin-right: rem(34px);
  $vertical-list-item_margin-bottom: rem(10px);

  $link_padding: $nav-link_padding-y 0;
  $link_font-family: $font-family-anonymous-pro;
  $link_font-size: rem(16px);
  $link_font-weight: $font-weight-bold;
  $link_color: $color-gray-chateau;
  $link_transition: $transition-medium-ease;
  $link_line-height: $line-height-lg;

  $link-active_color: $color-gray-l-23;

  user-select: none;

  &__list {
    display: flex;
    padding: 0;
    margin: 0;

    list-style: none;

    &_vertical {
      flex-direction: column;

      #{$this}__item:not(:last-child) {
        margin: 0 0 $vertical-list-item_margin-bottom 0;
      }
    }
  }

  &__item:not(:last-child) {
    margin-right: $item_margin-right;
  }

  &__item {
    position: relative;
  }

  &__link {
    padding: $link_padding;
    display: inline-block;

    white-space: nowrap;
    line-height: $link_line-height;

    font-family: $link_font-family;
    font-size: $link_font-size;
    font-weight: $link_font-weight;

    text-decoration: none;
    color: $link_color;

    transition: $link_transition;

    &:hover,
    &.active {
      color: $link-active_color;
    }

    &.active {
      cursor: default;

      &:before {
        content: none;
      }
    }
  }

  &__link-label {
    display: inline-block;

    transition: $link_transition;
  }
}
