.credentials {
  $this_padding-top: rem(70px);
  $this_padding-top_min-md: rem(70px);
  $this_padding-top_min-xl: rem(90px);

  $this_padding-bottom: rem(75px);
  $this_padding-bottom_min-md: rem(95px);
  $this_padding-bottom_min-xl: rem(110px);

  padding: $this_padding-top 0 $this_padding-bottom;

  @include media-min(md) {
    padding: $this_padding-top_min-md 0 $this_padding-bottom_min-md;
  }

  @include media-min(xl) {
    padding: $this_padding-top_min-xl 0 $this_padding-bottom_min-xl;
  }
}
