$list-item-marker-disc: url("data:image/svg+xml,%3Csvg width='5' height='5' viewBox='0 0 5 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.1426 4.64262C3.32593 4.64262 4.2852 3.68335 4.2852 2.50002C4.2852 1.37074 3.41155 0.445508 2.30325 0.363354L2.38238 2.26244H1.90625L2.0914 0.358022C0.931729 0.385216 0 1.33381 0 2.50002C0 3.68335 0.959275 4.64262 2.1426 4.64262ZM2.0964 0.35791H2.1888C2.17344 0.357585 2.15804 0.357422 2.1426 0.357422C2.12716 0.357422 2.11176 0.357585 2.0964 0.35791Z' fill='%233A3A3A'/%3E%3C/svg%3E%0A");
$list-item-marker-square: url("data:image/svg+xml,%3Csvg width='4' height='4' viewBox='0 0 4 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='4' height='4' fill='%233A3A3A'/%3E%3C/svg%3E%0A");
$list-item-marker-disc-size: rem(5px);
$list-item-marker-square-size: rem(4px);
$list-item-marker-left: -(rem(20px));

// Применяет дефолтные стили для создания маркера списка к псевдо-элементу
@mixin list-item-marker {
  content: "\00a0";

  position: absolute;

  left: 0;

  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 100% 40%;
}

// Задает круглый маркер для псевдо-элемента, который имеет дефолтные стили маркера заданные с помощью миксина list-item-marker
@mixin list-item-marker-disc {
  width: $list-item-marker-disc-size;

  background-image: $list-item-marker-disc;
}

// Задает квадратные маркер для псевдо-элемента, который имеет дефолтные стили маркера заданные с помощью миксина list-item-marker
@mixin list-item-marker-square {
  width: $list-item-marker-square-size;

  background-image: $list-item-marker-square;
}

@mixin custom-list-marker($size, $background-image) {
  content: "\00a0";

  position: absolute;

  left: 0;
  width: $size;

  background-image: $background-image;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 100% 40%;
}
