.about-our-advantages {
  $this_padding-top: rem(50px);
  $this_padding-top_min-md: rem(45px);
  $this_padding-top_min-xl: rem(80px);

  $this_padding-bottom: rem(70px);
  $this_padding-bottom_min-xl: rem(90px);

  padding: $this_padding-top 0 $this_padding-bottom;

  .advantages-slider-slide {
    &__text {
      font-size: rem(18px);
    }
  }

  @include media-min(md) {
    padding-top: $this_padding-top_min-md;

    .advantages-slider-slide {
      &__text {
        font-size: rem(17px);
      }
    }
  }
  @include media-min(xl) {
    padding: $this_padding-top_min-xl 0 $this_padding-bottom_min-xl;
    // .advantages-slider-slide {
    //   &__text {
    //     font-size: rem(16px);
    //   }
    // }
  }
}
