.facility {
  $header-inner_padding-top: rem(50px) + $header_height;
  $header-inner_padding-top_min-md: rem(50px) + $header_height;
  $header-inner_padding-top_min-xl: rem(35px) + $header_height;

  $header-inner_padding-bottom: rem(60px);
  $header-inner_padding-bottom_min-md: rem(40px);
  $header-inner_padding-bottom_min-xl: rem(30px);

  $header-inner_margin-bottom_min-xl: rem(130px) - $header-inner_padding-bottom_min-xl;

  $name-container_margin-bottom: rem(30px);
  $name-container_margin-bottom_min-md: rem(40px);
  $name-container_margin-bottom_min-xl: rem(35px);

  $place-and-year-container_margin-bottom: rem(25px);
  $place-and-year-container_margin-bottom_min-md: 0;

  $place-container_margin-bottom: rem(6px);

  $place_font-size: rem(20px);

  &__image {
    width: 100%;
    object-fit: cover;
  }

  &__header-inner {
    padding-top: $header-inner_padding-top;
    padding-bottom: $header-inner_padding-bottom;
  }

  &__name-container {
    margin-bottom: $name-container_margin-bottom;
  }

  &__header {
  }

  &__name br {
    display: none;
  }

  &__place-container {
    margin-bottom: $place-container_margin-bottom;
  }

  &__place {
    font-size: $place_font-size;
    white-space: pre-line;
  }

  &__year {
    text-transform: lowercase;
  }

  &__place-and-year-container {
    margin-bottom: $place-and-year-container_margin-bottom;
  }

  &__logo-container {
    background-color: $background-color-base;
  }

  &__logo {
    mix-blend-mode: darken;
  }

  @include media-min(md) {
    &__header-inner {
      padding-top: $header-inner_padding-top_min-md;
      padding-bottom: $header-inner_padding-bottom_min-md;
    }

    &__name br {
      display: inline-block;
    }

    &__name-container {
      margin-bottom: $name-container_margin-bottom_min-md;
    }

    &__place-and-year-container {
      margin-bottom: $place-and-year-container_margin-bottom_min-md;
      text-align: right;
    }
  }

  @include media-min(xl) {
    &__header-line {
      position: absolute;

      top: 0;
      right: -1px;

      width: $block-divider_weight;
      height: 100%;

      background-color: $block-divider_color;
    }

    &__header-inner {
      height: 100vh;

      padding-top: $header-inner_padding-top_min-xl;
      padding-bottom: $header-inner_padding-bottom_min-xl;
      margin-bottom: $header-inner_margin-bottom_min-xl;

      flex-direction: column;
    }

    &__name-container {
      margin-bottom: $name-container_margin-bottom_min-xl;
    }

    &__place-and-year-container {
      margin-bottom: $place-and-year-container_margin-bottom_min-md;
      text-align: left;
    }

    &__header {
      display: flex;
    }

    &__name-container,
    &__logo-container,
    &__place-and-year-container {
      flex: 0 0 auto;
    }

    &__place-and-year-container {
      margin-top: auto;
    }
  }
}
