.view {
  // убирает скачек скролла к концу страницы во время анимации перехода страницы,
  // после того как страница была немного проскроллена
  overflow-anchor: none;
  // &_enter,
  // &_entering {
  //   position: absolute;
  //   opacity: 0;
  //   width: 100%;
  //   height: 100vh;
  //   overflow: hidden;
  // }

  &_prevent-scroll {
    height: 100vh;
    overflow: hidden;
  }
}
