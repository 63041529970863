.accordion {
  $header_border-bottom: $block-divider_weight solid $block-divider_color;
  $header-inner_padding-y: rem(15px);
  $header-inner_padding-right: rem(30px);

  $summary_font-family: $font-family-gl-interstate;
  $summary_font-weight: $font-weight-regular;
  $summary_font-size: rem(20px);
  $summary_color: $color-gray-l-26;
  $summary_line-height: $line-height-none;

  $body-inner_padding: rem(35px) rem(20px) rem(65px);
  $body_transition: $transition-slow-ease-in;

  $body_max-xl_padding: rem(35px) rem(25px) rem(45px);
  $body_max-sm_padding: rem(25px) 0 rem(48px);

  $toggle_margin-right: -$button-icon_padding;
  $toggle_transition: $transition-medium-ease;
  $toggle-icon_width: rem(9px);
  $toggle-icon_height: rem(11px);

  display: flex;
  flex-direction: column;

  &__header {
    position: relative;

    border-bottom: $header_border-bottom;

    cursor: pointer;
    user-select: none;
  }

  &_disabled &__header {
    cursor: default;
  }

  &__header-inner {
    position: relative;

    padding-top: $header-inner_padding-y;
    padding-bottom: $header-inner_padding-y;
    padding-right: $header-inner_padding-right;

    display: flex;
  }

  &__summary {
    margin-bottom: 0;
    font-family: $summary_font-family;
    font-weight: $summary_font-weight;
    font-size: $summary_font-size;
    color: $summary_color;
    // line-height: $summary_line-height;
  }

  &_disabled &__toggle {
    display: none;
  }

  &__toggle-icon {
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0.32, 0.86, 0.56, 1.46);
  }

  @media (hover: hover) {
    &__header:hover &__toggle-icon {
      animation-name: details-toggle-point-down;

      @keyframes details-toggle-point-down {
        0% {
          transform: translateY(0);
        }

        50% {
          transform: translateY(40%);
        }
        100% {
          transform: translateY(0);
        }
      }
    }
  }

  &__toggle {
    transition: $transition-medium-ease-out;
    transform-origin: center;

    position: absolute;

    right: 0;
    top: 0;
    bottom: 0;

    line-height: 0;
  }

  &_opened &__toggle {
    transform: rotate(180deg);
  }

  &__toggle-icon {
    width: $toggle-icon_width;
    height: $toggle-icon_height;
  }

  &__body {
    overflow: hidden;
  }

  &_disabled &__body {
    max-height: unset;
  }

  &__body-inner {
    padding: $body-inner_padding;

    @include media-max(xl) {
      padding: $body_max-xl_padding;
    }

    @include media-max(sm) {
      padding: $body_max-sm_padding;
    }
  }
}
