.cards {
  $this_border-weight: 1px;
  $this_border-color: $color-gray-l-51;
  $this_border-dash-width: 5px;
  $this_border-dash-space-between: 4px;

  background: get-background-dashed-border(
    $this_border-weight,
    (
      width: $this_border-dash-width,
      space: $this_border-dash-width,
    ),
    top left,
    $this_border-color
  );

  border-top: 1px solid transparent;
  border-left: 1px solid transparent;

  .card {
    z-index: 1;
  }

  &:after {
    content: "";
    position: relative;

    flex: 1 1 auto;
    height: $card_border-width;

    top: -1px;

    background-color: $background-color-base;
  }
}
