@import "./arrow";

.features-main-product-view {
  $image_z-index: 10;

  position: relative;

  display: flex;
  width: 100%;
  align-items: flex-start;

  &__svg-arrow {
    position: absolute;
    height: 148px;
  }

  &__inner {
    width: 100%;
  }

  &__image {
    position: relative;

    z-index: $image_z-index;
  }

  &__image-wrapper {
    position: relative;
    width: 100%;
    max-width: 100%;
  }

  @include media-max(sm) {
    justify-content: center;

    &__image-wrapper {
      height: rem(250px);
      // max-width: 80%;
    }

    &__image {
      height: auto;
      width: auto;
      margin: 0 auto;
      max-width: 70%;
      max-height: 100%;
    }
  }

  @include media-min(sm) {
    width: 70%;

    &__inner {
      width: auto;
    }

    &__image-wrapper {
      width: auto;
    }
  }

  @include media-min(lg) {
    width: 100%;

    &__inner {
      width: 70%;
    }

    &__image {
      width: 90%;
      margin: 0 auto;
    }
  }
}
