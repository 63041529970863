$download-icon_width: em(10px);
$download-icon_height: em(10px);

$download-icon_bottom-line-weight: 1px;

$download-icon-arrow-body_size: em(4px);
$download-icon-arrow-tip_size: em(4px);
$download-icon-arrow_space-between-arrow-and-bottom: em(2px);
$download-icon-arrow_translate-y-to-bottom: $download-icon_height -
  ($download-icon-arrow-body_size + $download-icon-arrow-tip_size);

.download-icon {
  position: relative;

  display: inline-block;

  width: $download-icon_width;
  height: $download-icon_height;

  border-bottom: $download-icon_bottom-line-weight solid currentColor;

  overflow: hidden;

  &__arrow {
    position: relative;
    display: inline-block;

    width: 100%;

    transition: $transition-medium-ease;
    vertical-align: top;

    transform: translate3d(0, 0, 0);
    will-change: transform;

    &:before,
    &:after {
      content: "";

      display: block;

      margin: 0 auto;
    }

    &:before {
      width: $download-icon-arrow-body_size;
      height: $download-icon-arrow-body_size;

      background-color: currentColor;
    }

    &:after {
      position: absolute;

      top: $download-icon-arrow-body_size;
      left: 0;
      right: 0;

      width: 0;
      height: 0;

      border: $download-icon-arrow-tip_size solid transparent;

      border-top-color: currentColor;
    }
  }
}
