$maps-item_min-md_padding-x: rem(6px);

.maps-item {
  $this_min-md_padding-x: $maps-item_min-md_padding-x;

  $map_background-color: $color-gray-l-20;

  $map_height: rem(350px);
  $map_min-md_height: rem(512px);
  $map_min-xl_height: rem(820px);

  $map-label_font-family: $font-family-gl-interstate;
  $map-label_font-size: rem(16px);
  $map-label_line-height: $line-height-none;
  $map-label_color: $color-white;

  $map-label_margin: rem(19px) 0 0 rem(15px);
  $map-label_min-sm_margin-left: rem(20px);
  $map-label_min-md_margin: rem(23px) 0 0 rem(22px);
  $map-label_min-xl_margin: rem(31px) 0 0 rem(40px);

  $header_margin-bottom: rem(20px);
  $header_min-md_margin-bottom: rem(29px);
  $header_min-xl_margin-bottom: rem(20px);

  &__body {
    position: relative;
  }

  &__map-label {
    position: absolute;

    margin: $map-label_margin;

    font-family: $map-label_font-family;
    font-size: $map-label_font-size;
    line-height: $map-label_line-height;
    color: $map-label_color;

    z-index: 1;
  }

  &__map-label-content {
    display: inline-block;
  }

  &__map {
    height: $map_height;
    background-color: $map_background-color;
  }

  &__header {
    margin-bottom: $header_margin-bottom;
  }

  @include media-min(sm) {
    &__map-label {
      margin-left: $map-label_min-sm_margin-left;
    }
  }

  @include media-min(md) {
    padding: 0 $this_min-md_padding-x;

    &__header {
      margin-bottom: $header_min-md_margin-bottom;
    }

    &__map {
      height: $map_min-md_height;
    }

    &__map-label {
      margin: $map-label_min-md_margin;
    }
  }

  @include media-min(xl) {
    &__header {
      margin-bottom: $header_min-xl_margin-bottom;
    }

    &__map {
      height: $map_min-xl_height;
    }

    &__map-label {
      margin: $map-label_min-xl_margin;
    }
  }
}
