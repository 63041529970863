.products-use-cases-description-use-cases {
  $list_margin-right: rem(30px);

  display: flex;
  justify-content: space-between;

  &__list {
    flex: 1 1 50%;
  }

  &__list:first-child {
    margin-right: $list_margin-right;
  }

  @include media-min(xxl) {
    justify-content: flex-start;

    &__list {
      flex: 0 1 auto;
    }

    &__list:first-child {
      margin-right: rem(115px);
    }
  }

  @include media-max(xl) {
    &__list:first-child {
      margin-left: rem(24px);
    }
  }

  @include media-max(sm) {
    &__list:first-child {
      margin-left: 0;
    }
  }
}
