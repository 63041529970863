// Кнопка

$button-icon_padding: rem(5px);

.button {
  display: inline-block;

  margin: 0;
  padding: 0;

  border: none;

  color: inherit;
  font: inherit;
  line-height: inherit;

  background-color: transparent;
  cursor: pointer;

  &_icon {
    padding: $button-icon_padding;
  }
}
