.products-nav-group {
  $this: &;

  $previews-container_margin-bottom: rem(20px);
  $previews-container_margin-bottom_min-md: rem(25px);
  $previews-container_margin-bottom_min-xl: rem(30px);

  $title-container_margin-bottom: rem(20px);
  $title-container_margin-bottom_min-md: rem(33px);
  $title-container_margin-bottom_min-xl: rem(33px);

  $title_font-size: rem(20px);
  $title_font-size_min-md: rem(26px);

  $previews-divider_border: 1px solid #b9b9b9;
  $subcategory-list-item-not-last_margin-bottom: rem(25px);

  &__previews-container {
    position: relative;
    display: block;
    margin-bottom: $previews-container_margin-bottom;
    overflow: hidden;

    &:after {
      content: "";

      display: block;

      padding-top: percentage(156 / 330);
      z-index: 0;
    }
  }

  &__title-container {
    // margin-bottom: $title-container_margin-bottom;
  }

  &__title {
    min-height: 2.5em;
    font-size: $title_font-size;
  }

  &__subcategory-list-item:not(:last-child) {
    margin-bottom: $subcategory-list-item-not-last_margin-bottom;
  }

  &__previews-container {
    display: block;
  }

  &__previews {
    position: relative;
    overflow: hidden;

    #{$this}__preview:last-child {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 0;
    }

    #{$this}__preview #{$this}-preview__image-container:last-child {
      border-left: $previews-divider_border;
    }
  }

  @include media-min(md) {
    &__previews-container {
      margin-bottom: $previews-container_margin-bottom_min-md;
    }

    &__title-container {
      // margin-bottom: $title-container_margin-bottom_min-md;
    }

    &__title {
      font-size: $title_font-size_min-md;
    }
  }

  @include media-min(xl) {
    &__previews-container {
      margin-bottom: $previews-container_margin-bottom_min-xl;
    }

    &__title-container {
      // margin-bottom: $title-container_margin-bottom_min-xl;
    }
  }
}
