// font-sizes

$font-size-base: 16px;

// font-weights

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 600;
$font-weight-black: 900;

// line-heights

$line-height-none: 1;
$line-height-xs: 1.05;
$line-height-sm: 1.1;
$line-height-md: 1.2;
$line-height-lg: 1.3;

// font-families

$font-family-anonymous-pro: AnonymousPro, "Lucida Console", Monaco, monospace;
$font-family-gl-interstate: GLInterstate, Arial, Helvetica, sans-serif;
$font-family-tt-commons: TTCommons, Arial, Helvetica, sans-serif;
