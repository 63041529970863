.menu {
  $this_background-color: $background-color-base;
  $this_transition: $transition-medium-ease;
  $link_color: $primary-color;

  $container_padding-top: rem(70px);
  $container_padding-top_min-xxs: rem(131px);
  $container_padding-top_min-md: rem(208px);

  $container_padding-bottom: rem(30px);
  $container_padding-bottom_min-md: rem(25px);

  $item_font-size: rem(18px);
  $item_font-size-min-xs: rem(24px);
  $item_font-size_min-md: rem(30px);

  $item_margin-bottom: em(20px, 18px);
  $item_margin-bottom_min-md: em(20px, 30px);

  $header-link_font-size: rem(30px);
  $header-link_font-size_min-xs: rem(40px);
  $header-link_font-size_min-md: rem(47px);

  $header_margin-bottom: rem(43px);
  $header_margin-bottom_min-md: rem(48px);

  $body_margin-bottom: rem(50px);

  $contact-not-last_margin-bottom: rem(10px);

  position: fixed;

  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100vh;

  overflow-y: auto;

  background-color: $this_background-color;

  visibility: hidden;
  opacity: 0;

  transition-property: opacity, visibility;
  transition-duration: 0.2s;

  color: $primary-color;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  &__container {
    padding-top: $container_padding-top;
    padding-bottom: $container_padding-bottom;

    height: 100%;

    overflow-y: auto;
  }

  &__inner {
    display: flex;
    flex-direction: column;

    align-self: stretch;
  }

  &__inner {
    &_enter,
    &_entering {
      display: none;
    }
  }

  &__header {
    margin-bottom: $header_margin-bottom;
  }

  &__link {
    font-size: inherit;
    vertical-align: middle;
  }

  &__header-link {
    font-size: $header-link_font-size;
  }

  .light &__link {
    color: $link_color;
  }

  &__item {
    font-size: $item_font-size;
  }

  &__item:not(:last-child) {
    margin-bottom: $item_margin-bottom;
  }

  &__body {
    margin-bottom: $body_margin-bottom;
  }

  &__footer {
    margin-top: auto;
  }

  &__contact:not(:last-child) {
    margin-bottom: $contact-not-last_margin-bottom;
  }

  @include media-min(xs) {
    &__item {
      font-size: $item_font-size-min-xs;
    }

    &__header-link {
      font-size: $header-link_font-size_min-xs;
    }
  }

  @include media-min(xxs) {
    &__container {
      padding-top: $container_padding-top_min-xxs;
    }
  }

  @include media-min(md) {
    &__header {
      margin-bottom: $header_margin-bottom_min-md;
    }

    &__item:not(:last-child) {
      margin-bottom: $item_margin-bottom_min-md;
    }

    &__item {
      font-size: $item_font-size_min-md;
    }

    &__header-link {
      font-size: $header-link_font-size_min-md;
    }

    &__container {
      padding-top: $container_padding-top_min-md;
      padding-bottom: $container_padding-bottom_min-md;
    }
  }
}
