.partners {
  $this_padding-top: rem(60px);
  $this_padding-top_min-md: rem(100px);
  $this_padding-top_min-xl: rem(120px);

  $this_padding-bottom: rem(165px);
  $this_padding-bottom_min-md: rem(190px);
  $this_padding-bottom_min-xl: rem(260px);

  $header_margin-bottom: rem(40px);
  $header_margin-bottom_min-md: 0;
  $header_margin-bottom_min-xl: rem(60px);

  $subheader_margin-bottom: rem(100px);
  $subheader_margin-bottom_min-md: rem(75px);
  $subheader_margin-bottom_min-xl: 0;

  padding: $this_padding-top 0 $this_padding-bottom;

  &__header {
    margin-bottom: $header_margin-bottom;
  }

  &__subheader {
    margin-bottom: $subheader_margin-bottom;
  }

  @include media-min(md) {
    padding: $this_padding-top_min-md 0 $this_padding-bottom_min-md;

    &__header {
      margin-bottom: $header_margin-bottom_min-md;
    }

    &__subheader {
      margin-bottom: $subheader_margin-bottom_min-md;
    }
  }

  @include media-min(xl) {
    padding: $this_padding-top_min-xl 0 $this_padding-bottom_min-xl;

    &__header {
      margin-bottom: $header_margin-bottom_min-xl;
    }

    &__title {
      font-size: $title-section-main_font-size;
    }

    &__subheader {
      margin-bottom: $subheader_margin-bottom_min-xl;
    }
  }
}
