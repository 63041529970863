.our-manufacture {
  $this_padding-top: rem(75px);
  $this_padding-top_min-md: rem(110px);
  $this_padding-top_min-xl: rem(80px);

  $this_padding-bottom: rem(120px);

  $header_margin-bottom: rem(70px);
  $header_margin-bottom_min-xl: rem(90px);

  $title-container_margin-bottom: rem(35px);
  $title-container_margin-bottom_min-md: 0;
  $title-container_margin-bottom_min-xl: rem(55px);

  $caption-container_margin-bottom: rem(35px);
  $caption-container_margin-bottom_min-md: rem(55px);
  $caption-container_margin-bottom_min-xl: 0;

  $list_margin-right_min-xl: rem(115px);

  $lists_gap: rem(15px);
  $lists_half-gap: $lists_gap / 2;

  $list_margin-bottom: $list-item_margin-bottom;
  $list_font-size: rem(17px);

  padding: $this_padding-top 0 $this_padding-bottom;

  &__header {
    margin-bottom: $header_margin-bottom;
  }

  &__body {
    overflow: hidden;
  }

  &__title {
    white-space: pre-line;
  }

  &__title-container {
    margin-bottom: $title-container_margin-bottom;
  }

  &__caption-container {
    margin-bottom: $caption-container_margin-bottom;
  }

  &__list:not(:last-child) {
    margin-bottom: $list_margin-bottom;
  }

  &__list {
    text-transform: lowercase;
    font-size: $list_font-size;
  }

  @include media-min(414px) {
    &__lists {
      display: flex;

      margin-left: -$lists_half-gap;
      margin-right: -$lists_half-gap;
    }

    &__list {
      flex: 1 1 50%;

      padding: 0 $lists_half-gap;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  @include media-min(md) {
    padding-top: $this_padding-top_min-md;

    &__title-container {
      margin-bottom: $title-container_margin-bottom_min-md;
    }

    &__caption-container {
      margin-bottom: $caption-container_margin-bottom_min-md;
    }
  }

  @include media-min(xl) {
    padding-top: $this_padding-top_min-xl;

    &__header {
      margin-bottom: $header_margin-bottom_min-xl;
    }

    &__title-container {
      margin-bottom: $title-container_margin-bottom_min-xl;
    }

    &__caption-container {
      margin-bottom: $caption-container_margin-bottom_min-xl;
    }

    &__list {
      flex: 0 0 auto;

      &:not(:last-child) {
        margin-right: $list_margin-right_min-xl;
      }
    }
  }
}
